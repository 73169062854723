'use client';

import { MouseEventHandler, useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useMedia } from 'react-use';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { breakpoints } from '@/app.config';
import TrashbinSVG from '@/icons/TrashbinSVG';
import VectorPlaySVG from '@/icons/VectorPlaySVG';

import { useCardRemove } from '@/components/MovieCard/useCardRemove';
import { useInvalidateUnfinishedMovies } from '@/views/HomeView/UnfinishedMovieList/useUnfinishedMovies';

import { UnfinishedMovie } from '@/api/types/home.types';

import { FadeTransition } from '@/components/Animations';
import { IconButton } from '@/components/Buttons';
import { Card, CardBody, CardImage, CardTitle, useCardHover } from '@/components/Card';
import { ForwardedLink } from '@/components/ForwardedLink';
import { MovieCardProps } from '@/components/MovieCard';
import { ProgressBar } from '@/components/ProgressBar';
import styles from '@/components/UnfinishedMovieCard/UnfinishedMovieCard.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 281 / 172;

// =================================================================

export interface UnfinishedMovieCardProps extends Pick<MovieCardProps, 'onRemoved'> {
  movie: UnfinishedMovie;
  deepRemove: boolean;
}

// =================================================================

export const UnfinishedMovieCard = (props: UnfinishedMovieCardProps) => {
  const { movie, deepRemove, onRemoved } = props;

  const {
    duration,
    moduleId,
    movieId,
    seasonId,
    fileId,
    lastPosition = 0,
    isWatched,
    snapshot,
    movieTitle,
  } = movie;

  const { ref, isHovered } = useCardHover<HTMLAnchorElement>();
  const { isRemoving, remove } = useCardRemove({
    deepRemove,
  });

  const isDesktop = useMedia(breakpoints.large, true);

  const t = useTranslations('common');

  const invalidateUnfinishedMovies = useInvalidateUnfinishedMovies(movieId, deepRemove);

  const handleRemove: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    remove({
      moduleId,
      movieId,
      onRemoved: () => {
        invalidateUnfinishedMovies();
        if (typeof onRemoved === 'function') {
          onRemoved();
        }
      },
    });
  };

  const generateHref = useMemo(() => {
    let baseHref = `/watch/${moduleId}/${movieId}`;

    if (fileId) baseHref += `?fileId=${fileId}`;
    if (seasonId) baseHref += `&seasonId=${seasonId}`;
    baseHref += '&autoplay=true';

    baseHref += isWatched ? '&lastPosition=0' : `&lastPosition=${lastPosition}`;

    return baseHref;
  }, [fileId, isWatched, lastPosition, moduleId, movieId, seasonId]);

  return (
    <ForwardedLink
      ref={ref}
      href={generateHref}
      className={clsx(styles.cardWrapper, {
        [styles.hovered]: isHovered,
      })}
    >
      <CardImage
        as="div"
        src={snapshot}
        alt={movieTitle}
        aspectRatio={CARD_ASPECT_RATIO}
        sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw`}
        className={styles.imageWrapper}
      >
        <FadeTransition inProp={isHovered || !isDesktop}>
          <IconButton
            as="button"
            icon={TrashbinSVG}
            iconWidth={24}
            iconHeight={24}
            title="Удалить из истории"
            onClick={handleRemove}
            disabled={isRemoving}
            className={styles.trashbinIcon}
          />
        </FadeTransition>

        <IconButton
          as="button"
          icon={VectorPlaySVG}
          title={t('download')}
          iconWidth={20}
          iconHeight={20}
          className={styles.playBtn}
        />

        <CardBody className={styles.cardOverlayWrapper}>
          <div className={styles.cardControlPanel}>
            <ProgressBar
              max={duration}
              value={isWatched ? duration : lastPosition}
              variant="primary"
              currentTime={lastPosition}
              duration={duration}
              className={styles.processBar}
            />
          </div>
        </CardBody>
      </CardImage>
      {movieTitle && (
        <div className={styles.title}>
          <CardTitle lines={1}>{movieTitle}</CardTitle>
        </div>
      )}
    </ForwardedLink>
  );
};

// =================================================================

export const UnfinishedMovieCardSkelton = (props: { isSerial?: boolean }) => {
  const { isSerial } = props;
  return (
    <Card className={styles.cardWrapper}>
      <div style={{ paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }} className={styles.skeltonImage}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src="/assets/images/collection-card-placeholder.png" alt="collection-card-skelton" />
      </div>
      {isSerial && (
        <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A">
          <div className={styles.title}>
            <Skeleton />
          </div>
        </SkeletonTheme>
      )}
    </Card>
  );
};
