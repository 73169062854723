'use client';

import { isMobile } from 'react-device-detect';
import { useMedia } from 'react-use';

import { breakpoints } from '@/app.config';
// import CalendarSVG from '@/icons/CalendarSVG';
// import ClockSVG from '@/icons/ClockSVG';
import ImdbSVG from '@/icons/ImdbSVG';
import KinopoiskSVG from '@/icons/KinopoiskSVG';

// import VolumeSVG from '@/icons/VolumeSVG';
import type { Banner } from '@/api/types/home.types';

import { normalizeRating } from '@/helpers/transformations.helpers';

import styles from '@/views/HomeView/BannerSlider/BannerContent.module.scss';

// import { BannerInfoBadge } from '@/views/HomeView/BannerSlider/BannerInfoBadge';
// import { BannerStatusBadge } from '@/views/HomeView/BannerSlider/BannerStatusBadge';

// =================================================================

interface BannerContentProps {
  banner: Banner;
}

// =================================================================

export const BannerContent = (props: BannerContentProps) => {
  const { banner } = props;

  const { rating } = banner;

  const isSmall = useMedia(breakpoints.small, true);

  const isMobileDevice = isMobile || !isSmall;

  if (isMobileDevice) {
    return null;
  }

  return (
    <div className={styles.bannerContent}>
      <div className={styles.topPanel}>
        {/* <BannerStatusBadge>Премьера</BannerStatusBadge> */}
        {rating.imdb_rating > 0 && (
          <div className={styles.rating}>
            <ImdbSVG />
            <span>{normalizeRating(rating.imdb_rating)}</span>
          </div>
        )}
        {/* Kinopoisk rating */}
        {rating.kp_rating > 0 && (
          <div className={styles.rating}>
            <KinopoiskSVG />
            <span>{normalizeRating(rating.kp_rating)}</span>
          </div>
        )}
      </div>
      <div className={styles.bannerBottom}>
        {/* <BannerInfoBadge icon={<CalendarSVG />} title="2024" />
        <BannerInfoBadge icon={<ClockSVG />} title="2 ч, 8 мин" />
        <BannerInfoBadge icon={<VolumeSVG />} title="RU, EN, UZ" /> */}
      </div>
    </div>
  );
};
