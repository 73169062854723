import clsx from 'clsx';

import { Card, CardBody } from '@/components/Card';
import styles from '@/components/MovieCard/MovieCardSkelton.module.scss';
import { ViewAllListButton, type ViewAllListButtonProps } from '@/components/ViewAllListButton';

// =================================================================

const CARD_ASPECT_RATIO = 181 / 272;
const TOP_CARD_ASPECT_RATIO = 161 / 232;

// =================================================================

export const MovieListViewAllButton = ({
  withoutAspectRatio = false,
  ...rest
}: ViewAllListButtonProps & { withoutAspectRatio?: boolean }) => {
  return (
    <Card
      className={clsx(
        styles.movieCard,
        styles.viewAllButton,
        withoutAspectRatio && styles.topMovieCard,
      )}
    >
      <div
        style={
          withoutAspectRatio
            ? { paddingBottom: `${100 / TOP_CARD_ASPECT_RATIO}%` }
            : { paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }
        }
        className={styles.skeltonImage}
      />
      <CardBody className={styles.movieCardBody}>
        <p className={styles.text}></p>
      </CardBody>

      <ViewAllListButton {...rest} />
    </Card>
  );
};
