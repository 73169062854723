'use client';

import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useMedia } from 'react-use';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { breakpoints } from '@/app.config';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { customLocalStorage } from '@/helpers/storage.helpers';

import { USER_ACCESS_TOKEN } from '@/constants/user-credentials.constants';

import { Button } from '@/components/Buttons';

import styles from '@/views/HomeView/HomePromocodeSection/HomePromocodeSection.module.scss';
import { promocodeItems } from '@/views/HomeView/HomePromocodeSection/config';

export const HomePromocodeSection = () => {
  const t = useTranslations('page.home.promocode');
  const { userAccessToken } = useVerification();

  const isLarge = useMedia(breakpoints.large, false);
  const isMobileDevice = isMobile || !isLarge;

  const currentBgOrderRef = useRef<number | null>(null);
  if (!currentBgOrderRef.current) {
    currentBgOrderRef.current = [1, 2, 3][Math.floor(Math.random() * 3)];
  }
  const currentBgOrder = currentBgOrderRef.current;

  if (userAccessToken) return null;

  return (
    <section className={styles.promocodeSection}>
      <Container className={clsx(styles.container, { [styles.dark]: currentBgOrder === 3 })}>
        <div className={styles.wrapper}>
          <Image
            src={`/assets/images/promocode-bg${isMobileDevice ? '-mobile' : ''}-${currentBgOrder}.png`}
            alt="promocode"
            fill
            className={styles.imagePromocode}
          />

          <div className={styles.content}>
            <h3 className={styles.title}>
              <span>{t('mostPopular')}</span> {t('moviesOnCinerama')}
            </h3>
            <div className={styles.items}>
              {promocodeItems.map(item => (
                <div key={item.title} className={styles.item}>
                  <div className={styles.count}>{item.count}</div>
                  <div className={styles.label}>{t(item.title)}</div>
                </div>
              ))}
            </div>
            <Button as="link" size="lg" href="/subscriptions" variant="primary-gradient">
              {t('buttonText')}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};
