'use client';

import { useState, useEffect, useRef } from 'react';

import ArrowLeftSVG from '@/icons/PaginationArrowLeftSVG';
import ArrowRightSVG from '@/icons/PaginationArrowRightSVG';

import type { Banner } from '@/api/types/home.types';

import { Swiper, SwiperClass, NavigationOptions, SwiperSlide } from '@/components/Slider/Swiper';

import { BannerContent } from '@/views/HomeView/BannerSlider/BannerContent';
import { BannerSlide } from '@/views/HomeView/BannerSlider/BannerSlide';
import styles from '@/views/HomeView/BannerSlider/BannerSlider.module.scss';

// =================================================================

interface BannerSliderProps {
  banners: Banner[];
}

// =================================================================

const BannerSlider = (props: BannerSliderProps) => {
  const { banners } = props;

  const previousSlideButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextSlideButtonRef = useRef<HTMLButtonElement | null>(null);

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const handleSwiperInit = (swiper: SwiperClass) => setSwiper(swiper);

  useEffect(() => {
    if (swiper) {
      if (swiper.params.navigation) {
        const navigation = swiper.params.navigation as NavigationOptions;
        navigation.prevEl = previousSlideButtonRef.current;
        navigation.nextEl = nextSlideButtonRef.current;
        swiper.navigation?.update();
      }
    }
  }, [swiper]);

  return (
    <section className={styles.bannerSliderWrapper}>
      <Swiper
        loop
        wrapperTag="ul"
        slidesPerView={1.75}
        spaceBetween={20}
        slidesPerGroup={1}
        centeredSlides
        breakpoints={{
          0: {
            slidesPerView: 1.75,
            spaceBetween: 12,
          },
          992: {
            slidesPerView: 1.75,
            spaceBetween: 30,
          },
        }}
        speed={700}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={{
          prevEl: previousSlideButtonRef.current,
          nextEl: nextSlideButtonRef.current,
        }}
        mousewheel={{ forceToAxis: true }}
        onInit={handleSwiperInit}
        className="home-banner"
      >
        {banners.map(banner => (
          <SwiperSlide key={banner.id} tag="li">
            <BannerSlide
              desktopBanner={banner.bannerPhotoWeb}
              mobileBanner={banner.bannerPhotoSquare}
              banner={banner}
            >
              <BannerContent banner={banner} />
            </BannerSlide>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Prev banner navigation */}
      <div className={styles.bannerTogglePrev}>
        <button ref={previousSlideButtonRef} className={styles.bannerToggleBtn}>
          <ArrowLeftSVG />
        </button>
      </div>

      {/* Next banner navigation */}
      <div className={styles.bannerToggleNext}>
        <button ref={nextSlideButtonRef} className={styles.bannerToggleBtn}>
          <ArrowRightSVG />
        </button>
      </div>
    </section>
  );
};

export default BannerSlider;
