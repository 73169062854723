'use client';

import { Fragment, ReactNode } from 'react';
import { Container } from 'react-bootstrap';

import { useHomeCollections } from '@/views/HomeView/HomeCollections/useHomeCollections';

import { HomeApi } from '@/api/domains/home.api';

import { LoaderButton } from '@/components/LoaderButton';

import { HomeCollectionsRow } from '@/views/HomeView/HomeCollections/HomeCollectionsRow';
import { HomeCollectionsRowSkelton } from '@/views/HomeView/HomeCollections/HomeCollectionsRowSkelton';

// =================================================================

interface HomeCollectionsProps {
  spliceIndex: number;
  spliceComponent: ReactNode;
}

// =================================================================

export const HomeCollections = (props: HomeCollectionsProps) => {
  const { spliceComponent, spliceIndex } = props;
  const { isLoading, handleButtonClick, hasNextPage, ref, data } = useHomeCollections();

  if (!data) {
    return <HomeCollectionsRowSkelton />;
  }

  return (
    <Fragment>
      {data.list.map((collection, index) => (
        <div key={collection.id}>
          {index === spliceIndex && spliceComponent}
          <HomeCollectionsRow
            id={collection.id}
            title={collection.title}
            movies={HomeApi.extractCollectionMovies(collection.movies)}
            cardType={collection.is_new ? 'top-movie' : 'movie'}
          />
        </div>
      ))}
      <Container className="mb-5">
        {hasNextPage && (
          <LoaderButton ref={ref} isLoading={isLoading} onClick={handleButtonClick} />
        )}
      </Container>
    </Fragment>
  );
};
