import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { OnlineTvApi } from '@/api/domains/online-tv.api';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

// =================================================================

export const useHomeTvList = () => {
  const { isInitialCheckDone } = useVerification();
  const locale = useLocale() as Locale;

  const {
    data: channels = [],
    refetch,
    status,
  } = useQuery({
    queryKey: HomeQueryKeys.homeOnlineTv(locale),
    queryFn: OnlineTvApi.fetchHomeTvList,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
    enabled: isInitialCheckDone,
    select: data => data.slice(0, 12),
  });

  return { channels, refetch, status };
};
