import { Fragment } from 'react';

import ChevronRightSVG from '@/icons/ChevronRightSVG';

import type { HomeCollectionList } from '@/api/types/home.types';
import { Movie } from '@/api/types/movie.types';

import { ForwardedLink } from '@/components/ForwardedLink';
import { MovieCard } from '@/components/MovieCard';
import { MovieListViewAllButton } from '@/components/MovieCard/MovieListViewAllButton';
import { TopMovieCard } from '@/components/MovieCard/TopMovieCard';

import styles from '@/views/HomeView/HomeCollections/HomeCollectionsRow.module.scss';
import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';

// =================================================================

export enum MovieCardTypeEnum {
  MOVIE = 'movie',
  TOP_MOVIE = 'top-movie',
}

// =================================================================

interface HomeCollectionsRowProps extends Pick<HomeCollectionList, 'id' | 'title'> {
  movies: Movie[];
  cardType: 'movie' | 'top-movie';
}

// =================================================================

export const HomeCollectionsRow = (props: HomeCollectionsRowProps) => {
  const { movies, title, cardType = MovieCardTypeEnum.MOVIE, id } = props;

  return (
    <HomeContentBaseSlider
      heading={
        <ForwardedLink href={`/collection/11/${id}`} className={styles.sectionHeading}>
          {title}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
    >
      {movies.map((movie, index) => (
        <div key={movie.id}>
          {cardType === MovieCardTypeEnum.MOVIE ? (
            <MovieCard movie={movie} />
          ) : (
            <TopMovieCard movie={movie} ratingIndex={index + 1} />
          )}
        </div>
      ))}
      <MovieListViewAllButton
        href={`/collection/11/${id}`}
        withoutAspectRatio={cardType === MovieCardTypeEnum.TOP_MOVIE}
      />
    </HomeContentBaseSlider>
  );
};
