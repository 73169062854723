import clsx from 'clsx';

import { breakpoints } from '@/app.config';
import { ClassNameProps } from '@/types/common.types';

import type { Movie } from '@/api/types/movie.types';

import { FadeTransition } from '@/components/Animations';
import { Card, CardBody, CardImage, CardOverlay, CardTitle, useCardHover } from '@/components/Card';
import { Marquee } from '@/components/Marquee/Marquee';
import styles from '@/components/MovieCard/TopMovieCard.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 161 / 232;

// =================================================================

export interface TopMovieCardProps extends ClassNameProps {
  movie: Movie;
  ratingIndex?: number;
}

// =================================================================

export const TopMovieCard = (props: TopMovieCardProps) => {
  const { className, movie, ratingIndex } = props;

  const { ref, isHovered } = useCardHover<HTMLDivElement>();

  const { module_id, id, title, poster } = movie;

  const classesRating = clsx(
    styles.rating,
    ratingIndex === 1 && styles.first,
    ratingIndex === 2 && styles.second,
    ratingIndex === 3 && styles.third,
  );

  return (
    <Card ref={ref} className={clsx(styles.topMovieCard, className)}>
      <div className={styles.ratingCountWrapper}>
        <h3 className={classesRating} data-text={ratingIndex}>
          {ratingIndex}
        </h3>
      </div>

      <CardImage
        as="link"
        href={`/watch/${module_id}/${id}`}
        src={poster}
        alt={title}
        aspectRatio={CARD_ASPECT_RATIO}
        sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw, ${breakpoints.large} 17vw`}
        imageClassName={styles.movieImage}
      >
        <FadeTransition inProp={isHovered}>
          <CardOverlay className={styles.topMovieCardOverlay}></CardOverlay>
        </FadeTransition>
      </CardImage>

      <CardBody className={styles.movieCardBody}>
        <CardTitle as="link" href={`/watch/${module_id}/${id}`} lines={1}>
          <Marquee isEnabled={isHovered}>{title}</Marquee>
        </CardTitle>
      </CardBody>
    </Card>
  );
};
