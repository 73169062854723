export const promocodeItems: { count: string; title: string }[] = [
  {
    count: '33 000+',
    title: 'moviesAndSerials',
  },
  {
    count: '170+',
    title: 'TVChannels',
  },
  {
    count: '1 600+',
    title: 'cartoons',
  },
  {
    count: '1 200+',
    title: 'uzbVideo',
  },
];
