import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { HomeApi } from '@/api/domains/home.api';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useHomeGenres = () => {
  const locale = useLocale() as Locale;

  const { data = [], isLoading } = useQuery({
    queryKey: HomeQueryKeys.homeGenres(locale),
    queryFn: HomeApi.fetchHomeGenres,
    select: data => {
      const filteredData = data.filter(genre => genre.image_web);
      return filteredData.slice(0, 10);
    },
    staleTime: Infinity,
  });

  return { genres: data, isLoading };
};
